import React from 'react';
import { Link } from "gatsby"
import PostList from './ThoughtPostList';
import { StaticQuery, graphql } from "gatsby"

const thoughts = () => (
    <StaticQuery
    query={graphql`
      {
        allWpPage(filter: {slug: {eq: "home-dev"}}) {
            nodes {
              home {
                someOurThoughtsTitle
                someOurThoughtsSubtitle
                someOurThoughtImage {
                  altText
                  mediaItemUrl
                }
                wordsBy
                wordsByTitle
                readTime
              }
            }
        }
        allWpBlog(sort: {order: DESC, fields: date}) {
            nodes {
              title
              slug
              featuredImage {
                node {
                  sourceUrl
                  altText
                  mediaDetails {
                    sizes {
                      sourceUrl
                    }
                  }
                }
              }
              author {
                node {
                  firstName
                  lastName
                }
              }
              blogCustomPost {
                wordsByOfBlog {
                  ... on WpTeam {
                    id
                    title
                  }
                }
              }
            }
        }
      }
    `}
    render={data => {
        const pageContent = data.allWpPage.nodes[0].home;
        const blogPosts = data.allWpBlog.nodes;
        // console.log(blogPosts);
    return (
        <div className="py-120">
            <div className="container">
                <div className="row mb-lg-5 mb-4">
                    <div className="col-md-11 col-lg-9 col-xxl-10">
                        <h2 className='h1' data-aos="fade-up" data-aos-delay="200" dangerouslySetInnerHTML={{__html: pageContent?.someOurThoughtsTitle}}/>
                        <p  data-aos="fade-up" data-aos-delay="400">{pageContent?.someOurThoughtsSubtitle}</p>
                    </div>
                </div>
                
                
                {blogPosts && blogPosts.slice(0, 1).map((post, index) => (
                    <Link aria-label={post.slug} to={'/blog/' + post.slug} className="b2b-section d-block" key={index}>
                        <picture>
                          <source media="(min-width:1800px)" srcSet={post.featuredImage.node?.sourceUrl} />
                          <source media="(min-width:1280px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[4].sourceUrl} />
                          <source media="(min-width:992px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[1].sourceUrl} />
                          <source media="(min-width:575px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[3].sourceUrl} />
                          <img loading="lazy" width="1236" height="512" src={post.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} alt={post.featuredImage.node?.altText} className="w-100 gatsby-image-wrapper gatsby-image-wrapper-main-blog img-fluid " />
                        </picture>
                        <div className="row">
                            <div className="col-lg-7 col-md-9 col-sm-10 col-10 col-xxl-9">
                                <div className="content-box" data-aos="fade-in" data-aos-delay="200">
                                    <div className="h6 text-uppercase">{pageContent?.readTime}</div>
                                    <h3 dangerouslySetInnerHTML={{__html: post.title}} />
                                    {post.blogCustomPost?.wordsByOfBlog?.title ? (
                                      <p className="lead">{pageContent?.wordsBy} {post.blogCustomPost?.wordsByOfBlog?.title}</p>
                                    ):(
                                      <p className="lead">{pageContent?.wordsBy} {post.author.node.firstName + ' ' + post.author.node.lastName}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </Link>
                ))}

                {blogPosts?.length > 0 && (
                  <div className="row align-items-center justify-content-center justify-content-xl-between">
                      <div className="d-flex col-sm-10 col-xl-5 m-auto">
                          <div className="m-xl-auto w-100 mb-xl-0 mb-md-5 mb-4 d-flex flex-xl-wrap justify-content-xl-start justify-content-between">
                            {/*  data-aos="fade-up" data-aos-delay="0.2s" */}
                          <h2 className="mb-xl-3 h1 w-100 mb-0">Discover <em>more</em></h2>
                          <Link aria-label="Blog" to="/blog" className="btn text-uppercase btn-link d-inline-flex align-items-center">
                              ViEW ALL

                              <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                              <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                              </svg>
                          </Link>
                          </div>
                      </div>
                      <div className="col-xl-6 col-sm-10">
                          <PostList posts={blogPosts} />
                      </div>
                  </div>
                )}
            </div>
            </div>
        );
    }}
    ></StaticQuery>
);

export default thoughts;