import React from 'react'

export default function partnershipList({posts}) {
  return (
    <div className="container">
      {posts && posts.slice(1).map((partner, index) => (
        <div className="row" data-aos="fade-up" data-aos-delay="200" key={index}> 
          <div className="col-md-3 col-lg-4 mb-md-0 mb-3">
              <div className='d-flex d-md-block align-items-center justify-content-between'>
                <h2 className='mb-0 mb-md-4 mb-xxl-5' dangerouslySetInnerHTML={{__html: partner.title}} />
                <img loading="lazy" width="" height="" src={partner.logo && partner.logo.mediaItemUrl} alt={partner.logo && partner.logo.altText} className="img-fluid gatsby-image-wrapper" />
              </div>
          </div>
          <div className="col-md-9 col-lg-8">
            <p className='lead-lg' dangerouslySetInnerHTML={{__html: partner.description}} />

            <ul className="list-unstyled p-0">
              {partner.specifications && partner.specifications.map((label, index) => (
                <li key={index * 88} dangerouslySetInnerHTML={{__html: label.title}} />
              ))}
            </ul>
          </div>
        </div>
      ))}  
      </div>
  )
}
