import React from 'react'
import { Link } from "gatsby"

const PostList = ({posts}) => {
  return (
    <ul className="post-list m-0 list-unstyled">
        {posts && posts.slice(1, 4).map(post => (
            <li  data-aos="fade-up" data-aos-delay="200" key={post.slug}>
                <div className="d-flex align-items-center">
                    {/* <img loading="lazy" src={post.blogCustomPost.smallImage && post.blogCustomPost.smallImage.mediaItemUrl} alt={post.blogCustomPost.smallImage && post.blogCustomPost.smallImage.altText} className="gatsby-image-wrapper" /> */}
                      <picture>
                          <source media="(min-width:575px)" srcSet={post.featuredImage.node?.mediaDetails.sizes[0].sourceUrl} />
                          <img loading="lazy" width="120" height="85" src={post.featuredImage.node?.mediaDetails.sizes[2].sourceUrl} alt={post.featuredImage.node?.altText} className="gatsby-image-wrapper" />
                      </picture>
                    <div>
                      <p className='mb-1' dangerouslySetInnerHTML={{__html: post.title}} />

                      <Link aria-label={post.slug} to={'/blog/'+post.slug} style={{fontSize : '0.69em'}} className="btn text-uppercase btn-link d-inline-flex align-items-center">
                        READ More

                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-arrow-right-short" viewBox="0 0 16 16">
                          <path fillRule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8z"/>
                        </svg>
                      </Link>
                    </div>
                </div>
            </li>
        ))}        
    </ul>
  )
}

export default PostList;