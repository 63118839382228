import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PartnershipList from "../components/partnershipList"
import Thoughts from "../components/thoughts"
import { graphql } from "gatsby"

const partnerDetails = ({data}) => {
  const page = data.wpTechnicalPartnership.technicalPartnership.logos;
  const post = data.wpTechnicalPartnership;
  // console.log(page);

  return (
  <Layout>
    <Seo title={post.seo.title} image="https://dev.alkye.com/wp-content/uploads/2021/12/Sonia-1-1-scaled-1.webp" description={post.seo.metaDesc} focuskw={post.seo.focuskw} />
    
    {page && page.slice(0,1).map((partner, index) => (
      <div className="page-intro page-inner" key={index}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-12 col-xl-4 col-xxl-4 mb-lg-0 mb-3 text-lg-center order-lg-2" data-aos="fade-up">
              <img loading="lazy" width="300" height="91" src={partner.logo && partner.logo.mediaItemUrl} alt={partner.logo && partner.logo.altText} style={{filter: 'brightness(0.85)'}} className="img-fluid gatsby-image-wrapper" />
            </div>
            <div className="col-md-12 col-xl-7 col-xxl-8" data-aos="fade-up">
              <h1 className="title mb-3 mb-md-4 pb-xl-2" dangerouslySetInnerHTML={{__html: partner.title}} />
            </div>
            <div className="col-md-12 pt-2 col-xl-9 col-xxl-10 order-lg-3" data-aos="fade-up">
              <p className="lead-lg" dangerouslySetInnerHTML={{__html: partner.description}} />
            </div>
          </div>
        </div>
      </div>
    ))}

    <div className="services partnership-services py-md-5 py-4">
      <PartnershipList posts={page} />
    </div>

    <Thoughts />
  
  </Layout>
 );
}

export default partnerDetails

export const query = graphql `
  query($slug: String) {
    wpTechnicalPartnership(slug: { eq: $slug }) {
      seo {
        title
        metaDesc
        focuskw
      }
      technicalPartnership {
        logos {
          title
          specifications {
            title
          }
          logo {
            altText
            mediaItemUrl
          }
          description
        }
      }
    }
  }
`